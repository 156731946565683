// 服务配置
const env = process.env.VUE_APP_NODE_ENV
const gateway = env === 'development' ? '/services' : '/cop'

export default function () {
  let service = {
    domainName: '', // 主域名
    img: '', // 图片域名
    PreviewH5Url: '',
    gateway, // 流量网关前缀,后面的才是微服务后端代码前缀
    copServer: gateway + '/cop-server', // https://sst-private-api.cits.com/cop/cop-server/doc.html
    nbs: gateway + '/cop-server', // nbs转发接口
  }

  switch (env) {
    // 开发, 本地开发走vue代理
    case 'development': {
      service = {
        ...service,
        domainName: '',
        PreviewH5Url: 'https://sst-wap.ctshk.com',
      }
      break
    }
    // 开发联调环境
    case 'sst': {
      service = {
        ...service,
        domainName: 'https://sst-private-api.ctshk.com',
        PreviewH5Url: 'https://sst-wap.ctshk.com',
      }
      break
    }
    // 测试环境
    case 'staging': {
      service = {
        ...service,
        domainName: 'https://sit-private-api.ctshk.com',
        PreviewH5Url: 'https://sit-wap.ctshk.com',
      }
      break
    }
    // 生产
    case 'production': {
      service = {
        ...service,
        domainName: 'https://private-api.ctshk.com',
        PreviewH5Url: 'https://wap.ctshk.com',
      }
      break
    }
  }

  return service
}
