import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c6874570"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("span", _hoisted_1, [_ctx.isCancel ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    onClick: _ctx.oncancel,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.finCancelText), 1)]),
    _: 1
  }, 8, ["onClick", "disabled"])) : _createCommentVNode("", true), _ctx.isConfirm ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "primary",
    disabled: _ctx.loading,
    onClick: _ctx.onConfirm
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.finConfirmText), 1)]),
    _: 1
  }, 8, ["disabled", "onClick"])) : _createCommentVNode("", true)]);
}