import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    styleIcon() {
      try {
        const url = require(`@/assets/svg/${this.icon}.svg`);
        return {
          mask: `url(${url}) no-repeat 50% 50%`,
          '-webkit-mask': `url(${url}) no-repeat 50% 50%`,
          '-moz-mask': `url((${url}) no-repeat 50% 50%`,
          '-o-mask': `url((${url}) no-repeat 50% 50%`,
          '-ms-mask': `url((${url}) no-repeat 50% 50%`
        };
      } catch (e) {
        return {};
      }
    }
  }
});