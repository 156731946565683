import { useGlobalStore } from '@/stores/global'
const gConfig = require('/build/config')
// 是否可以访问的菜单
export function isVisit(url: string) {
  const useGlobal = useGlobalStore()

  return gConfig.mock.menu ? true : useGlobal.menu.urlList.includes(url)
}

// 默认重定向页面
export const defaultRedirectUrl = '/home'

// 是否可以跳转页面
export function isJumpPage(url: string) {
  if (isVisit(url)) return url
  else return defaultRedirectUrl
}
