import { customMessage } from '@/utils/CustomMessage'

// 消息提示
const Tips = {
  success(opt = {}) {
    customMessage({ type: 'success', ...opt })
  },
  error(opt = {}) {
    customMessage({ type: 'error', ...opt })
  },
}

export default Tips
