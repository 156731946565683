import DialogFooter from './DialogFooter.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TwoDialog',
  components: {
    DialogFooter
  },
  emits: ['update:modelValue', 'close', 'confirm'],
  props: {
    modelValue: Boolean,
    isFooterButton: Boolean,
    loading: Boolean,
    isCancel: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    isConfirm: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: '确认'
    }
  },
  computed: {
    finCancelText() {
      return this.cancelText ?? this.$t('common.button.cancel');
    },
    finConfirmText() {
      return this.confirmText ?? this.$t('common.button.confirm');
    }
  },
  methods: {
    onclose() {
      this.$emit('update:modelValue', false);
      this.$emit('close', false);
    },
    onConfirm() {
      this.$emit('confirm');
    }
  }
});