import { BaseApi } from '../couplingAjax'
import { AxiosAjaxParams } from '../base/type'
import config from '../config'
import { Login } from '@/services/cop/typings'

const gConfig = require('/build/config')

const ajax = (opt: AxiosAjaxParams) =>
  BaseApi(opt, {
    prefix: config.gateway, // 路径前缀
    dataField: 'data', // 取值字段
    codeField: 'success', // 判断正确错误字段
    codeNum: true, // 返回是否正确取值
    msgField: 'message', // 提示信息获取
    tipsCode: 'errorCode', // 错误号
  })

export default {
  login: (opt?: AxiosAjaxParams): Promise<Login> => {
    if (gConfig.mock.login) {
      return Promise.resolve({
        id: 1,
        name: '戴海天',
        token: '111111',
      })
    }

    return ajax({
      url: '/login',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...opt,
    })
  },

  logout: (opt?: AxiosAjaxParams) => {
    if (gConfig.mock.login) {
      return Promise.resolve()
    }
    return ajax({
      url: '/logout',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...opt,
    })
  },
  // userInfo: (opt?: AxiosAjaxParams): Promise<UserDetails> => {
  //   if (gConfig.mock.login) {
  //     return Promise.resolve({
  //       id: 1,
  //       userName: '戴海天',
  //       vendorId: 1,
  //       vendorName: '戴海天',
  //       roles: [
  //         {
  //           id: 1,
  //           roleId: 1,
  //           roleDesc: '戴海天',
  //           roleName: '戴海天',
  //           roleType: 1,
  //         },
  //       ],
  //     })
  //   }
  //   return ajax({ url: '/user/get-user-detail', method: 'get', ...opt })
  // },
  // menu: {
  //   list: (opt?: AxiosAjaxParams): Promise<Menu> => {
  //     if (gConfig.mock.login) {
  //       return Promise.resolve([])
  //     }
  //     return ajax({ url: '/menu/current-user-menu', method: 'get', ...opt })
  //   },
  // },
}
