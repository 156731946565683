export default {
  accountPasswordLogin: '账号密码登录',
  login: '登    录',
  account: '账号',
  password: '密码',
  rules: {
    username: '请输入账号',
    password: '请输入密码',
  },
}
