import 'normalize.css/normalize.css'
import './styles/index.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'
import { i18n } from './i18n'
import components from './components'

const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(i18n)
components(app)
import { userInfoService, getMenus } from '@/power/preLoad'
import { isNoLoginPage } from '@/power/noPowerPage'

async function loadApp() {
  try {
    // 只有需要当前权限校验的页面才需要调用以下接口
    if (!isNoLoginPage()) await Promise.all([userInfoService(), getMenus()])

    app.mount('#app')
  } catch (e) {
    app.mount('#app')
  }
}

loadApp()
