const config = require('../../build/config')

// 不需要进行权限管控的页面
export const noPowerPage: string[] = ['/login', '/404', '/403', '/', '/home', '/doc/commitment']

// 不可二次跳转的页面，登录之后不可以再次跳转的,不可以作为历史存储的
export const nopPage: string[] = ['/login', '/404', '/403', '/']

// 无需登录访问的页面
export const noLogin: string[] = []

// 是否是无登录页面
export function isNoLoginPage(path?: string) {
  if (path) noLogin.includes(path)
  else {
    const pathname = window.location.pathname

    return !!noLogin.find((li) => {
      return config.publicPath + li === pathname
    })
  }
}
