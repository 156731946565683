// src/i18n/index.js
import { nextTick } from 'vue'
import zhCn from './Language/zh-cn'
import { createI18n } from 'vue-i18n'
import { Lang, LangType } from './config'

const language = Lang.zhCn
type MessageSchema = typeof zhCn
export const i18n = createI18n<[MessageSchema], 'zh-cn'>({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: language, // m默认简体中文
  fallbackLocale: language, // 后备语言
  messages: {
    [Lang.zhCn]: zhCn,
  },
})

// 设置本地语言
export function setI18nLanguage(locale: LangType) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    // @ts-ignore
    i18n.global.locale.value = locale
  }
  document.querySelector('html')?.setAttribute('lang', locale)
}

// 加载语言
export async function loadLocaleMessages(locale: LangType) {
  if (!locale) return nextTick()

  const messages = await import(`./Language/${locale}/index`)
  i18n.global.setLocaleMessage(locale, messages.default)

  setI18nLanguage(locale)
  return nextTick()
}
