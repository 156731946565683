/**
 * 统一处理出入参均需要加密解密的情况
 */
import { isUploadFile } from '@/services/tool/fileStream'
import SysNoNeedEncrypt from '@/services/const/sysNoNeedEncrypt'

// 研发排查问题专用字段，参数名请勿外传！！！
const noNeedEncrypt = window.localStorage.getItem('noNeedEncrypt')

export const isSysNeedEncryptFunc = (opt: any) => {
  if (noNeedEncrypt && noNeedEncrypt?.length > 0) {
    // 本地配置此参数，则接口不进行加解密，方便研发排查问题
    return false
  } else {
    // 1. 非上传文件流 2. 非下载文件流 3. 非特殊排出的系统或接口
    return (
      !isUploadFile(opt) && !opt.file && !SysNoNeedEncrypt.some((item) => opt.url?.includes(item))
    ) // 服务是否需要加密
  }
}
