import { useGlobalStore } from '@/stores/global'
import axios from 'axios'
// 重要页面置灰处理
export async function isPageAsh() {
  const gStore = useGlobalStore()
  try {
    const config = (await axios.get('https://oss.cits.com.cn/static/gray-global-config.json')).data
    gStore.setPageGreyedOut(config.isGlobalGray ?? false)
  } catch (e) {
    gStore.setPageGreyedOut(false)
  }
}
