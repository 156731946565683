import type { App, Plugin } from 'vue'

import SvgIcon from './SvgIcon'
import TwoDialog from './TwoDialog'
// 批量注册列表
const component: Plugin[] = [SvgIcon, TwoDialog]

const install = (app: App): void => {
  component.forEach((com) => {
    app.use(com)
  })
}

export default install
