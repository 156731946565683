export default {
  业务标准化: '业务标准化',
  '规范业务流程，提高合作效率': '规范业务流程，提高合作效率',
  产品信息化: '产品信息化',
  '产品规范管理，信息快捷更新': '产品规范管理，信息快捷更新',
  交易线上化: '交易线上化',
  '打破时空限制，实时在线确认': '打破时空限制，实时在线确认',
  管理数智化: '管理数智化',
  '数据辅助分析，智能服务业务': '数据辅助分析，智能服务业务',
}
