import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DialogFooter = _resolveComponent("DialogFooter");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    "custom-class": "c-dialog-one",
    "append-to-body": "",
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    onClose: _ctx.onclose,
    "model-value": _ctx.modelValue
  }, _ctx.$attrs), {
    header: _withCtx(() => [_renderSlot(_ctx.$slots, "header")]),
    footer: _withCtx(() => [_renderSlot(_ctx.$slots, "footer", {}, () => [_ctx.isFooterButton ? (_openBlock(), _createBlock(_component_DialogFooter, {
      key: 0,
      "is-cancel": _ctx.isCancel,
      "is-confirm": _ctx.isConfirm,
      "cancel-text": _ctx.finCancelText,
      "confirm-text": _ctx.finConfirmText,
      loading: _ctx.loading,
      onCancel: _ctx.onclose,
      onConfirm: _ctx.onConfirm
    }, null, 8, ["is-cancel", "is-confirm", "cancel-text", "confirm-text", "loading", "onCancel", "onConfirm"])) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16, ["onClose", "model-value"]);
}