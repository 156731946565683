import { ElMessage } from 'element-plus'

type Type = 'success' | 'info' | 'warning' | 'error' | undefined
export const customMessage = ({ msg = '', type = 'success' as Type, tipsCode = '' }) => {
  if (!msg) return null
  if (!tipsCode) {
    ElMessage({
      message: msg,
      type,
      showClose: true,
    })
  }
  if (tipsCode) {
    ElMessage({
      message: msg,
      type,
      showClose: true,
    })
    // ElMessage({
    //   dangerouslyUseHTMLString: true,
    //   message: `${msg} <a href="/nbs-pc/wiki-search?name=${tipsCode}" style="color: var(--primary-color);" target="_blank">更多帮助</a>`,
    //   type,
    //   showClose: true,
    // })
  }
}
