import cop from '../cop'
import router from '@/router'
import { useGlobalStore } from '@/stores/global'
export async function loginOut() {
  const GlobalStore = useGlobalStore()
  const outPage = () => {
    GlobalStore.clearData()
    router.replace({
      path: '/login',
    })
  }
  try {
    await cop.logout()
    localStorage.setItem('vendor', '')
    outPage()
    return Promise.resolve(true)
  } catch (e) {
    outPage()
    return Promise.resolve(e)
  }
}
