import { useGlobalStore } from '@/stores/global'

export function getToken() {
  return useGlobalStore().token
}

export function setToken(token: string) {
  const useGlobal = useGlobalStore()
  useGlobal.setToekn(token)
}
