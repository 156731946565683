import { loginOut } from '@/services/tool/LoginSet'
import { initGlobalState, MicroAppStateActions } from 'qiankun'
const baseConfig = require('../../build/config')

const { publicPath, platform } = baseConfig
import router from '@/router'
import { useGlobalStore } from '@/stores/global'
// 初始化 state
export let actions: MicroAppStateActions
function getConfig() {
  const GlobalStore = useGlobalStore()
  const { menu } = GlobalStore
  actions = initGlobalState({
    menuPutAway: menu.mode, // 菜单是否收起
  })
  const domainName = GlobalStore.isDevelopment ? '//localhost' : ''
  const props = {
    platform, // 代表从哪个平台下发
    language: GlobalStore.language,
    mainPrefix: publicPath,
    store: GlobalStore,
    router,
    actions,
    components: {},
    apis: {
      loginOut,
    },
  }
  const setTing = {
    // micro business system 业务微服务
    microWEP: {
      domainName: domainName,
    },
    tripEditor: {
      domainName: domainName,
    },
    orderWeb: {
      domainName: domainName,
    },
  }
  switch (GlobalStore.env) {
    // 开发, 本地开发走vue代理
    case 'development': {
      setTing.microWEP = {
        domainName: `${domainName}:7600`,
      }
      setTing.tripEditor = {
        domainName: `${domainName}:7700`,
      }
      setTing.orderWeb = {
        domainName: `${domainName}:7800`,
      }
      break
    }
    // sst开发环境
    case 'sst': {
      break
    }
    // 测试环境
    case 'staging': {
      break
    }
    // 生产
    case 'production': {
      break
    }
  }
  // 带左侧菜单的访问地址
  const config = [
    {
      name: 'copWeb',
      entry: `${setTing.microWEP.domainName}/cop-web/`,
      container: '#app-qiankun',
      activeRule: publicPath + '/cop-web',
      props,
      _fullscreen: [
        //'/cop-web/product-sys/list'
      ],
    },
    {
      name: 'tripEditor',
      entry: `${setTing.tripEditor.domainName}/trip-web/`,
      container: '#app-qiankun',
      activeRule: publicPath + '/trip-web',
      props,
      _fullscreen: ['/trip-web/trip/edit'],
    },
    {
      name: 'order-web',
      entry: `${setTing.orderWeb.domainName}/order-web/`,
      container: '#app-qiankun',
      activeRule: publicPath + '/order-web',
      props,
      _fullscreen: [],
    },
  ]

  return {
    config,
  }
}

export default getConfig
