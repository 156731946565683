import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useGlobalStore } from '@/stores/global'
import { isNoLoginPage, nopPage } from '@/power/noPowerPage'
import { defaultRedirectUrl, isVisit } from '@/power/tool'

const config = require('../../build/config')

// 资料文档或者是承诺书
import DocData from './DocData'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/ui',
    name: '/ui',
    component: () => import('@/views/ui/index.vue'),
    meta: {
      title: 'ui规范',
      auth: false,
    },
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      auth: true, // 控制是否跳过授权，false为跳过
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/layout/index.vue'),
    // 路由模式变革，嵌套路由下不可以有上级路由的前缀
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
        },
      },
    ],
  },
  ...DocData,
]

const router = createRouter({
  // process.env.BASE_URL
  history: createWebHistory(config.publicPath),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const GlobalStore = useGlobalStore()
  const toCopy = { ...to }
  // 记录上一次的页面
  const path = toCopy.path ? toCopy.path : '/'

  const { title, auth } = to.meta

  let docTitle = config.title

  if (GlobalStore.menu.keyMap && GlobalStore.menu.keyMap[path]?.name) {
    docTitle = GlobalStore.menu.keyMap[path].name
  } else {
    docTitle = title ?? config.title
  }
  document.title = docTitle as string

  // 框架页面直接跳过的处理
  if (auth === false) {
    next()
    return false
  }

  // 无参数路由
  const noParamsPath = path.split('?')[0]
  const noPage = nopPage.includes(noParamsPath)
  // 如果是无登录访问页面则直接访问
  if (isNoLoginPage(noParamsPath)) {
    next()
    return false
  }

  if (!noPage) {
    GlobalStore.setUpperRoute(toCopy.fullPath)
  }

  const loginPage = '/login'

  if (GlobalStore.token) {
    // 已经登录
    if (toCopy.path === loginPage) {
      next(defaultRedirectUrl)
    } else {
      if (isVisit(noParamsPath)) {
        // 如果有访问权限
        // 跟菜单重定向
        path === '/' ? next(defaultRedirectUrl) : next()
      } else {
        console.warn('您没有菜单权限')
        next(defaultRedirectUrl)
      }
    }
  } else {
    if (toCopy.path === loginPage) next()
    else next(loginPage)
  }
})

export default router
