const uploadFileKeys = ['file', 'fileName']
export const isUploadFile = (baseApiOptions: any = {}) => {
  const { data, headers } = baseApiOptions
  // 判断文件头
  if (headers?.['Content-Type'] !== 'multipart/form-data') return false

  // 判断文件内容
  if (!(data instanceof FormData)) return false
  const hasFile = uploadFileKeys.some((key) => data.has(key))
  if (!hasFile) return false

  return true
}
