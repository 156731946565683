import { defineStore } from 'pinia'
import { Login, Menu, MenuItem, UserDetails } from '@/services/cop/typings'
import { Lang } from '@/i18n/config'
import { actions } from '@/qiankun/config'
const baseConfig = require('../../build/config')

export type StateMenu = {
  mode?: boolean // false展开，true收缩
  urlList: string[] // 权限中可以访问的菜单列表
  all?: Menu // 所有菜单集合, 仅接口数据
  keyMap?: {
    // url: MenuItem,菜单集合数据，包括不在菜单列表的菜单
    [key: string]: MenuItem
  }
}
export interface State {
  language: string | Lang
  version: string | number
  env: string | 'development' | 'sst' | 'staging' | 'production'
  isDevelopment: boolean
  token: string
  loginUserInfo: Login | null
  userDetails: UserDetails | null // 用户登录后实际用户信息
  upperRoute: string
  menu: StateMenu
  pageGreyedOut: boolean
}

export const useGlobalStore = defineStore({
  id: 'global', // id必填，且需要唯一
  state: (): State => {
    return {
      language: Lang.zhTw, // 默认中文
      version: new Date().getTime(), // 版本信息,利用版本信息的不一致，控制页面整体实例刷新
      env: process.env.VUE_APP_NODE_ENV,
      isDevelopment: process.env.VUE_APP_NODE_ENV === 'development',
      upperRoute: '', // 上一个页面的路由
      token: '',
      loginUserInfo: null,
      userDetails: null,
      // 菜单数据集合
      menu: {
        urlList: [],
        all: [],
        keyMap: {},
        mode: false,
      },
      pageGreyedOut: false,
    } as State
  },
  actions: {
    // 用于退出登录
    clearData() {
      this.token = ''
      this.loginUserInfo = null
      this.menu = {
        urlList: [],
        all: [],
        keyMap: {},
        mode: this.menu.mode,
      }
    },
    setToekn(token: string) {
      this.token = token
    },
    setLoginUserInfo(
      { userInfo, userDetails } = {} as { userDetails?: UserDetails; userInfo?: Login | null },
    ) {
      if (userInfo) this.loginUserInfo = userInfo
      if (userDetails) this.userDetails = userDetails
    },
    setUpperRoute(upperRoute: string) {
      this.upperRoute = upperRoute
    },
    setMenu(data: StateMenu) {
      this.menu = {
        ...this.menu,
        ...data,
      }
    },
    setMenuMode(mode: boolean) {
      this.menu.mode = mode
      actions.setGlobalState({ menuPutAway: mode })
    },
    // 设置语言
    setLanguage(lang: Lang) {
      this.language = lang
    },
    // 设置全局置灰处理
    setPageGreyedOut(isGary = false) {
      this.pageGreyedOut = isGary
    },
  },
  persist: {
    key: baseConfig.storageName,
    storage: localStorage,
  },
})
