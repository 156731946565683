import { BaseApi } from '../couplingAjax'
import { AxiosAjaxParams } from '../base/type'
import config from '../config'
import { Menu, UserDetails } from '../cop/typings'

const gConfig = require('/build/config')

const ajax = (opt: AxiosAjaxParams) =>
  BaseApi(opt, {
    prefix: config.copServer, // 路径前缀
    dataField: 'data', // 取值字段
    codeField: 'success', // 判断正确错误字段
    codeNum: true, // 返回是否正确取值
    msgField: 'message', // 提示信息获取
    tipsCode: 'errorCode', // 错误号
  })

export default {
  user: {
    userInfo: (opt?: AxiosAjaxParams): Promise<UserDetails> => {
      if (gConfig.mock.login) {
        return Promise.resolve({
          id: 1,
          userName: '戴海天',
          vendorId: 1,
          vendorName: '戴海天',
          roles: [
            {
              id: 1,
              roleId: 1,
              roleDesc: '戴海天',
              roleName: '戴海天',
              roleType: 1,
            },
          ],
        })
      }
      return ajax({ url: '/user/get-user-detail', method: 'get', ...opt })
    },
    getCustomerProperty: (opt: AxiosAjaxParams): any => {
      return ajax({
        url: `/vendor-server/vendor-info/getCustomerProperty`,
        method: 'post',
        ...opt,
      })
    },
    checkPassword: (opt?: AxiosAjaxParams): any => {
      return ajax({ url: '/user/checkPassword', method: 'post', ...opt })
    },
    updatePassword: (opt?: AxiosAjaxParams): any => {
      return ajax({ url: '/user/updatePassword', method: 'post', ...opt })
    },
  },
  menu: {
    list: (opt?: AxiosAjaxParams): Promise<Menu> => {
      if (gConfig.mock.login) {
        return Promise.resolve([])
      }
      return ajax({ url: '/menu/current-user-menu', method: 'get', ...opt })
    },
  },
}
