// 注意为全小写字符
import { ToJSon } from '@/constant/tool'

// 注意全部转为小写，语言目录和该枚举名称保持一致
export enum Lang {
  zhCn = 'zh-cn', // 简体中文
  zhTw = 'zh-tw', // 繁体中文
  en = 'en', // 美国英语
}
export type LangType = 'zh-cn' | 'zh-tw' | 'en'
export type LangItem = {
  value: Lang
  label: string
}

export function LangEnum() {
  const list: LangItem[] = [
    { value: Lang.zhCn, label: '简体中文' },
    { value: Lang.zhTw, label: '繁體中文' },
    //{ value: Lang.en, label: 'English' },
  ]
  const ketJson = ToJSon(list)
  return {
    list,
    ketJson,
  }
}
