export type NoShowMenuItem = {
  path: string
  meta: {
    title: string
  }
}
export type NoShowMenu = {
  [key: string]: NoShowMenuItem[]
}
// 不在菜单列表中展示的菜单
export const noShowMenu: NoShowMenu = {
  '/cop-web/product-sys/list': [
    {
      path: '/cop-web/product-sys/edit',
      meta: {
        title: '产品编辑',
      },
    },
    {
      path: '/trip-web/trip/edit',
      meta: {
        title: '行程编辑',
      },
    },
    {
      path: '/cop-web/product-sys/inventory/management',
      meta: {
        title: '库存管理',
      },
    },
  ],
  '/cop-web/visa-sys/list': [
    {
      path: '/cop-web/visa-sys/detail',
      meta: {
        title: '签证详情',
      },
    },
  ],
  '/order-web/order-list': [
    {
      path: '/order-web/receive-order',
      meta: {
        title: '接单页',
      },
    },
    {
      path: '/order-web/order-detail',
      meta: {
        title: '订单详情',
      },
    },
  ],
}
