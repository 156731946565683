import type { RouteRecordRaw } from 'vue-router'

const prefix = '/doc'

const productSys: RouteRecordRaw[] = [
  {
    path: prefix + '/commitment',
    component: () => import('@/views/doc/DataCommitment/index.vue'),
    meta: {
      title: '资源上传承诺书',
    },
  },
]

export default productSys
