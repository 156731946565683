import { Headers, AxiosAjaxParams, CatchData, CouplingConfig } from './base/type'
import { setToken, getToken } from './tool/setToken'
import { ajax } from './base/axiosAjax'
import config from './config'
import Tips from './base/tips'
import { i18n } from '@/i18n'
import { loginOut } from './tool/LoginSet'
import qs from 'qs'
import { antiShake } from '@/utils/antiShakingAndThrottling'
import { getSecretHash, encryptionSM2, encryptSM4, decryptSM4 } from '@/utils/cryptoTool'
import { isSysNeedEncryptFunc } from '@/services/tool/isSysNeedEncrypt'

// 口令封装处理
const handlerToken = (header: Headers = {}): Headers => {
  header['requestId'] = new Date().getTime()

  const token = getToken()
  if (!token) return header
  header['Authorization'] = token
  return header
}
// 401退出登录
const signOut = antiShake(() => {
  loginOut()
  Tips.error({
    msg: i18n.global.t('tool.services.reLoginPrompt'),
    title: i18n.global.t('tool.services.error'),
  })
}, 1000)

// 处理opt传入参数
const handlerData = (opt: AxiosAjaxParams, apiBase = {} as { prefix: string }) => {
  const { prefix } = apiBase
  opt.baseURL = opt.baseURL ? opt.baseURL : config.domainName
  opt.url = prefix + opt.url

  opt.headers = opt.headers ?? { 'Content-Type': 'application/json' } // 设置默认headers
  opt.headers = handlerToken(opt.headers)
  opt.file = opt.file ?? false // 是否为文件模式，文件下载模式为后端直接下载文件，不做处理判断

  opt.responseType = opt.responseType ?? 'json'
  opt.isResponse = opt.isResponse ?? false // 是否直接获取response数据，避免因为简化data数据获取导致无法获取完整数据情况
  opt.reLogin = opt.reLogin ?? true // 是否判断401状态跳转到登录页面

  return opt
}
// 错误信息
const handlerErrorMessage = (error: boolean | string, message: string, tipsCode: string) => {
  error &&
    Tips.error({
      msg: error !== true ? error : message ?? i18n.global.t('tool.services.retry'),
      tipsCode,
    })
}
// 成功信息
const handlerSuccessMessage = (success: boolean | string, message: string, tipsCode = '') => {
  success &&
    Tips.success({
      msg: success !== true ? success : message ?? i18n.global.t('tool.services.success'),
      tipsCode,
    })
}

// 业务接口
async function BaseApi(
  opt: AxiosAjaxParams = {},
  {
    prefix = '',
    codeField = 'success',
    dataField = 'data',
    codeNum = true,
    msgField = 'message',
    tipsCode = 'errorCode',
  }: CouplingConfig,
): Promise<any> {
  opt = handlerData(opt, { prefix }) // 参数预处理
  const error = opt.error ?? true // 成功提醒
  const success = opt.success ?? false // 错误提醒

  // 特殊格式请求处理
  const posts = ['put', 'post', 'patch']
  if (
    posts.includes(opt.method as string) &&
    opt.headers &&
    opt.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    opt.data = qs.stringify(opt.data)
  }

  let keyStr = ''
  // 设置需要加密
  const isSysNeedEncrypt = isSysNeedEncryptFunc(opt) // 服务是否需要加密
  if (isSysNeedEncrypt) {
    // 签名、加密
    const { key, encryptorStr } = encryptionSM2()
    const { iv, encryptBody } = encryptSM4(key, JSON.stringify(opt.data))
    keyStr = key
    if (opt && opt.headers) {
      opt.headers['secret-hash'] = getSecretHash()
      opt.headers['secret'] = encryptorStr
    }
    /** web加解密针对不同请求方式的处理
     * POST PUT 请求方式，请求和响应都加解密
     * 其他请求方式，请求不加解密，响应加解密
     */
    if (opt && opt.method) {
      if (opt.method.toLowerCase() === 'post' || opt.method.toLowerCase() === 'put') {
        opt.data = {
          data: encryptBody,
          iv,
        }
      }
    }
  }

  try {
    let result = await ajax(opt) // 请求接口
    // 服务级别解密
    if (isSysNeedEncrypt) {
      result = {
        ...result,
        data: decryptSM4(keyStr, result.data),
      }
    }
    if (result.headers['authorization']) {
      setToken(result.headers['authorization'] as string)
    }
    if (opt.reLogin && result.status === 401) {
      signOut()
      return Promise.reject(result)
    }

    switch (opt.file) {
      case false: {
        // 解密后端返回信息
        const response = result.data
        const code = response[codeField]
        const data = response[dataField]
        const message = response[msgField] as string
        const errCode = response[tipsCode]
        if (code === codeNum) {
          // 提前处理正确错误
          handlerSuccessMessage(success, message)

          return Promise.resolve(opt.isResponse ? response : data)
        } else {
          if (!opt.hideError) {
            handlerErrorMessage(error, message, errCode as string)
          }
          return Promise.reject(response)
        }
      }
      // 走文件模式下
      case true: {
        return Promise.resolve(result)
      }
    }
  } catch (e: any) {
    switch (opt.file) {
      case false: {
        const response = e.response as CatchData
        if (opt.reLogin && response?.status === 401) signOut()
        else {
          const resData = response?.data ?? {}
          const message = resData[msgField] as string
          const errCode = resData[tipsCode] as string
          handlerErrorMessage(error, message, errCode)
        }

        return Promise.reject(e)
      }
      case true: {
        return Promise.reject(e)
      }
    }
  }
}

export { BaseApi }
