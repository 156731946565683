import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DialogFooter',
  emits: ['cancel', 'confirm'],
  props: {
    loading: Boolean,
    isCancel: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String
    },
    isConfirm: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String
    }
  },
  computed: {
    finCancelText() {
      return this.cancelText ?? this.$t('common.button.cancel');
    },
    finConfirmText() {
      return this.confirmText ?? this.$t('common.button.confirm');
    }
  },
  methods: {
    oncancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      this.$emit('confirm');
    }
  }
});