const packageData = require('../package.json')

module.exports = {
  platform: 'cop',
  title: '供应商管理系统',
  name: packageData.name,
  storageName: 'cop-pc',
  publicPath: '/cop-pc',
  mock: {
    menu: false,
    login: false,
  },
}
