import type { App, Plugin } from 'vue'
// 批量注册列表
const components: Plugin[] = []

const install = (app: App) => {
  components.forEach((com) => {
    app.use(com)
  })
}

export default install
