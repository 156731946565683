// 加密工具文件
import CryptoJS from 'crypto-js'
import smCrypto from 'sm-crypto'
const SM4 = require('gm-crypt').sm4

const sm2 = smCrypto.sm2

const pubilcKey =
  '04AB193D3FC8EB9C83B7D208D7B280F214C8913571FA93BE2B5190700FA7A3E2B3AADB20FE58E42B35F6EFBD5934525BA22629515015F9F9A85264AD771B19B3B1'

// 生成uuid
export function uuid() {
  // var s = []
  const s: any[] = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  const uuid = s.join('')
  return uuid
}

export const getSecretHash = () => {
  let encryptorStr = ''
  encryptorStr = CryptoJS.MD5(pubilcKey).toString()
  return encryptorStr
}

// 获取长度16的随机key
export const getKeyOrIv = (length: any) => {
  const randomWord = CryptoJS.lib.WordArray.random(length)
  const hexKey = randomWord.toString(CryptoJS.enc.Hex)
  return hexKey
}

// key加密
export function encryptionSM2() {
  const cipherMode = 1
  const key = getKeyOrIv(8)
  // 配合后端处理，加密后内容拼接04
  const encryptorStr = '04' + sm2.doEncrypt(key, pubilcKey, cipherMode)
  return { key, encryptorStr }
}

// body加密
export function encryptSM4(key: any, requestBody: any) {
  const iv = getKeyOrIv(8)
  const sm4 = new SM4({
    key,
    mode: 'cbc',
    iv,
    cipherType: 'text',
  })
  const encryptBody = sm4.encrypt(requestBody)
  return { iv, encryptBody }
}

// 解密
export function decryptSM4(key: any, encryptData: any) {
  const iv = encryptData.iv
  const data = encryptData.data
  const sm4 = new SM4({
    key,
    mode: 'cbc',
    iv,
    cipherType: 'text',
  })
  const encryptBody = sm4.decrypt(data)
  return JSON.parse(encryptBody)
}
