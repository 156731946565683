import { useGlobalStore } from '@/stores/global'
import { noPowerPage } from '@/power/noPowerPage'
import { noShowMenu } from '@/power/noShowMenu'
import copServer from '@/services/copServer'
import { MenuItem } from '@/services/cop/typings'
// 获取服务器数据并且更新缓存数据
export async function userInfoService(errorStatus = true) {
  try {
    const useGlobal = useGlobalStore()
    if (!useGlobal.token) return Promise.resolve(null)

    const id = useGlobal.loginUserInfo?.id
    if (!id) return Promise.resolve(null)

    const info = await copServer.user.userInfo({
      data: { id },
      error: errorStatus,
    })
    const { orgCompanyId, vendorId } = info ?? {}
    //客户信息
    const customerInfo = await copServer.user.getCustomerProperty({
      data: { vendorId, orgCompanyId, merchantProperty: 0 }, //0 客户 1供应商 默认0
    })
    useGlobal.setLoginUserInfo({ userDetails: { ...info, customerInfo } })
  } catch (e) {
    return Promise.reject(e)
  }
}
// 获取用户菜单数据
export async function getMenus(errorStatus = true) {
  const useGlobal = useGlobalStore()
  try {
    if (!useGlobal.token) return Promise.resolve(null)

    let list = await copServer.menu.list({ error: errorStatus })

    list = list ?? []

    // 加入权限忽略的菜单
    const urlList = [...noPowerPage]

    const keyList: {
      [key: string]: MenuItem
    } = {}
    list.forEach((li) => {
      const { url } = li
      urlList.push(url)
      keyList[url] = li

      if (noShowMenu[url]) {
        for (let i = 0; i < noShowMenu[url].length; i++) {
          const son = noShowMenu[url][i]
          keyList[son.path] = {
            name: son.meta.title,
            url: son.path,
          }
          urlList.push(son.path)
        }
      }
    })

    useGlobal.setMenu({ urlList, all: list, keyMap: keyList })
  } catch (e) {
    // 无菜单的时候加入默认不控制菜单
    useGlobal.setMenu({ urlList: [...noPowerPage] })
    return Promise.reject(e)
  }
}
