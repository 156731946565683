// 常见的公共名称
import common from './common'
// 全局布局
import layout from './pages/layout'
// 登录页
import login from './pages/login'
// 首页
import home from './pages/home'
// 公共服务,包含一些工具函数等
import tool from './tool'
export default {
  // 公共字段,表示常用的非业务
  common,
  layout,
  login,
  tool,
  home,
}
